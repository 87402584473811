import { useEffect, useState, useRef } from "react"
import requestApi, { requestApiJackpot } from "../utils/requests";
import { useParams } from "react-router-dom";

export default function Jackpot(){

    const {gameId, lang, defaultGain, jackpotId} = useParams();
    const [gains, setGains] = useState([]);
    const [index1, setIndex1] = useState(0);
    const [index2, setIndex2] = useState(0);
    const [index3, setIndex3] = useState(0);
    const [hasPlay, setHasPlay] = useState(false);
    const [jackpot,setJackpot] = useState(null);
    const [formValues, setFormValues] = useState({
        game_type: 'jackpot',
    });
    const [gainId, setGainId] = useState(null);
    const [show, setShow] = useState(false);
    const [btn, setBtn] = useState('./assets/jouez fr.png');

    const searchGains = async () => {
        const res = await requestApi('/getGainByGameIdForPlay', 'post', {game_id: gameId});
        if(res.data.success){
            setGains(res.data.data);
        }
    }

    const handleChange = (key, value) => {
        setFormValues(prevValues => ({
            ...prevValues,
            [key]: value
        }));
    };

    const play = async() => {
        if(hasPlay){
            return;
        }

        setHasPlay(true);

        display();

        const res = await requestApi('/play', 'post', {
            input: {
                game_id: gameId,
                lang: lang,
                player_data: formValues
            }
        });

        setGainId(res.data.data.id);

        if(res.data.data.id === defaultGain){
            const index = random_int(0, gains.length - 1);
            setIndex1(index + 1);
            setIndex2(index + 2);
            setIndex3(index + 3);
        }else{
            const index = searchIndexById(gains, res.data.data.id);
            setIndex1(index);
            setIndex2(index);
            setIndex3(index);
        }
    }

    function display(){
        setTimeout(() => {
            setShow(true);
        }, 9000)
    }

    const load = async() => {
        const res = await requestApiJackpot('/getjackpotById', 'post', {id: jackpotId});
        if(res.data.success){
            setJackpot(res.data.data);
        }
    }

    useEffect(()=>{
        searchGains();
        load();
        switch(lang){
            case 'fr':
                setBtn('/assets/jouez fr.png');
                break;
            case 'nl':
                setBtn('/assets/jouez nl.png');
                break;
            case 'en':
                setBtn('Play');
                break;
            default:
                setBtn('Play');
        }
    }, []);

    useEffect(()=>{console.log(index1, index2, index3)}, [index1, index2, index3]);

    if(!jackpot){
        return null;
    }

    return(
        <div style={{width: '90%', margin: 'auto'}}>
            <div style={{display: 'flex', flexDirection: 'row', position: 'absolute', width: jackpot.width_box + '%', top: jackpot.pos_box_y + '%', left: jackpot.pos_box_x + '%', height: jackpot.heigth_box + '%', overflow: 'hidden'}}>
                <Lamelle hasPlay={hasPlay} duration={'4s'} position={index1} gains={gains} lang={lang}/>
                <Lamelle hasPlay={hasPlay} duration={'6s'} position={index2} gains={gains} lang={lang}/>
                <Lamelle hasPlay={hasPlay} duration={'8s'} position={index3} gains={gains} lang={lang}/>
            </div>
            <img style={{position: 'absolute', width: '90%'}} height={'auto'} width={'100%'} src="https://jackpot.web-treeosk.online/box.png" alt="Box" />
            {
                !hasPlay &&
                <div className="text-center" style={{width: '60%', margin: 'auto', zIndex: 100, position: 'absolute', left: '20%', marginTop: '35%', padding: '4%', borderRadius: '2%'}}>
                    <div className="container">
                        <form onSubmit={play}>
                            <div className="row">
                                {
                                    jackpot.forms_fields.map((form_field)=>{
                                        return(
                                            <div className="col col-12 mt-1">
                                                <input onChange={(e) => handleChange(form_field.label, e.target.value)} value={formValues[form_field.label] || ''} placeholder={form_field.label} required className="form-control" type={form_field.type} />
                                            </div>
                                        );
                                    })
                                }
                            </div>
                            <button type='submit' style={{fontSize: '4em', border: 'none', background: 'none', outline: 'none'}}><img alt="play" width={'70%'} src={btn}/></button>
                        </form>
                    </div>
                </div>
            }
            {
                show &&
                <div style={{position: 'absolute', width: '80%', textAlign: 'center', top: '10%', left: '10%'}}>
                    <img width={'80%'} style={{margin: 'auto'}} src={`https://api-gain.web-treeosk.online/src/img/${gainId}_${lang}_visu.png`} alt="" />
                </div>
            }
        </div>
    )
}

function Lamelle({gains, position, lang, duration, hasPlay}){

    const divRef = useRef(null);
    const [divHeight, setDivHeight] = useState(0);

    useEffect(() => {
        if (divRef.current) {
            setDivHeight(divRef.current.scrollHeight);
            console.log(divHeight)
        }
    }, [position]);

    return(
        <div ref={divRef} style={{transitionDuration: duration, flex: 1, display: 'flex', flexDirection: 'column', marginTop: (!hasPlay)? '0px' : -((divHeight / gains.length / 20 * position) + (divHeight / gains.length / 20 * position) * gains.length * random_int(4, 4)) + 90 + 'px' }}>
            {Array.from({ length: 20 }).map((_, index) => (
                gains.map((gain, index_2) => (
                    <img 
                    id={`gain_${index}_${index_2}`}
                    key={gain.id} 
                    width={'100%'} 
                    src={`https://api-gain.web-treeosk.online/src/img/${gain.id}_${lang}.png`} 
                    alt="gain" 
                    />
                ))
            ))}
        </div>
    )
}

function searchIndexById(gains, id){
    let i = 0;
    for(const gain of gains){
        if(gain.id === id){
            return i
        }
        ++i;
    }
}

function random_int(min, max) {
    return Math.floor(Math.random() * (max - min) + min);
}

