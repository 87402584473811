import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Jackpot from './components/Jackpot';
import Create from './components/Create';
import List from './components/List';
import Edit from './components/Edit';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='' element={<List/>} />
        <Route path='game/:gameId/lang/:lang/default/:defaultGain/jackpot/:jackpotId' element={<Jackpot />} />
        <Route path='edit/:gameId' element={<Edit/>} />
        <Route path='create' element={<Create/>} />
        <Route path='list' element={<List/>} />
      </Routes>
    </Router>
  );
}

export default App;