import { useState } from "react";
import { requestApiJackpot } from "../utils/requests";

function Create(){

    const [name, setName] = useState('');

    return(
        <div className="container mt-5">
            <h1>
                Créer
            </h1>
            <input onChange={(e)=>{setName(e.target.value)}} className="form-control" value={name} placeholder={'name'} type="text" />
            <button onClick={async () => {const res = await requestApiJackpot('/create', 'post', {input: {name: name}})}} className="btn btn-primary mt-3">
                Créer
            </button>
        </div>
    )
}

export default Create;