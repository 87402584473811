import axios from 'axios';

export default function requestApi(path, method, data) {
    const url = 'https://api-gain.web-treeosk.online' + path;

    if (method === 'post') {
        return axios.post(url, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    } else if (method === 'get') {
        return axios.get(url, { params: data });
    } else {
        console.log('error promise');
        return Promise.reject(new Error('Méthode de requête invalide'));
    }
}

export function requestApiJackpot(path, method, data) {
    const url = 'https://api-jackpot.web-treeosk.online' + path;

    if (method === 'post') {
        return axios.post(url, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    } else if (method === 'get') {
        return axios.get(url, { params: data });
    } else {
        console.log('error promise');
        return Promise.reject(new Error('Méthode de requête invalide'));
    }
}