import { useEffect, useState } from "react";
import { requestApiJackpot } from "../utils/requests";

export default function List(){

    const [jackpots, setJackpots] = useState([]);

    const load = async () => {
        const res = await requestApiJackpot('/getalljackpots', 'post', {});

        if(res.data.success){
            setJackpots(res.data.data);
        }
    }

    useEffect(()=>{
        load();
    }, []);

    return(
        <div className="container mt-5">
            <h1>
                Les jackpots :
            </h1>
            <div className="row">
                {
                    jackpots.map((jackpot)=>{
                        return(
                            <div className="col col-3 mt-5" key={jackpot.id}>
                                <button onClick={()=>{window.location.href = `/edit/${jackpot.id}`}} className="btn btn-primary">{jackpot.name}</button>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
}