import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { requestApiJackpot } from "../utils/requests";

export default function Edit(){

    const {gameId} = useParams();

    const [jackpot, setJackpot] = useState(null);

    const load = async () => {
        const res = await requestApiJackpot('/getjackpotById', 'post', {
            id: gameId,
        });

        if(res.data.success){
            setJackpot(res.data.data);
        }
    }

    useEffect(()=>{
        load();
    }, []);

    if(jackpot){
        return(
            <div className="container mt-5">
                <h1>
                    Edition
                </h1>

                <div className="row">
                    <div className="col col-6">
                        <div className="row">
                            <div className="col col-6">
                                <input onChange={(e)=>{setJackpot({...jackpot, pos_box_y: e.target.value})}} value={jackpot.pos_box_y} placeholder="Top" className="form-control" type="number" />
                            </div>
                            <div className="col col-6">
                                <input onChange={(e)=>{setJackpot({...jackpot, pos_box_x: e.target.value})}} value={jackpot.pos_box_x} placeholder="Left" className="form-control" type="number" />
                            </div>
                            <div className="col col-6 mt-3">
                                <input onChange={(e)=>{setJackpot({...jackpot, heigth_box: e.target.value})}} value={jackpot.heigth_box} placeholder="Hauteur" className="form-control" type="number" />
                            </div>
                            <div className="col col-6 mt-3">
                                <input onChange={(e)=>{setJackpot({...jackpot, width_box: e.target.value})}} value={jackpot.width_box} placeholder="Largueur" className="form-control" type="number" />
                            </div>
                            <button onClick={async()=>{await requestApiJackpot('/update', 'post', {input: jackpot})}} className="btn btn-primary mt-5">Editer</button>
                        </div>
                    </div>
                    <div className="col col-6">
                        <div style={{position: 'absolute', height: 560, width: 480, margin: 'auto', background: 'rgba(0,0,0,0.2)', border: 'solid 1px black', boxShadow: '12px 12px 16px rgba(0,0,0,0.2)'}}>
                            <div style={{width: 504, margin: 'auto', position: 'absolute', height: '100%'}}>
                                <div style={{zIndex: 50, marginLeft: jackpot.pos_box_x + '%', marginTop: jackpot.pos_box_y + '%',position: 'absolute', display: 'flex', flexDirection: 'row', height: jackpot.heigth_box + '%', overflow: 'hidden', border: 'solid 4px black', width: jackpot.width_box + '%'}}>
                                    
                                </div>
                                <div style={{position: 'absolute'}} className="text-center">
                                    <img style={{width: '90%', margin: 'auto'}} height={'auto'} width={'100%'} src="https://jackpot.web-treeosk.online/box.png" alt="Box" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col col-6 mt-5">
                        <div className="row">
                            <FormFields jackpot={jackpot} load={load}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function FormFields({jackpot, load}){

    const [label, setLabel] = useState('');
    const [type, setType] = useState('text');

    const add = async () => {
        const res = await requestApiJackpot('/createformfield', 'post', {input: {label, type, jackpot_id: jackpot.id}});
        if(res.data.success){
            setLabel('');
            setType('text');
            load();
        }
    }

    return(
        <>
            <div className="col col-6">
                <input value={label} onChange={(e)=>{setLabel(e.target.value)}} type="text" className="form-control" />
            </div>
            <div className="col col-6">
                <select value={type} onChange={(e)=>{setType(e.target.value)}} className="form-control">
                    <option value="text">Text</option>
                    <option value="number">Number</option>
                    <option value="email">Email</option>
                    <option value="date">Date</option>
                </select>
            </div>
            <button onClick={add} className="btn btn-success mt-3">Ajouter</button>
            
            {
                jackpot.forms_fields.map((form_field)=>{
                    return(
                        <div className="row mt-1" key={form_field.id}>
                            <div className="col col-3">
                                <span>
                                    {form_field.label}
                                </span>
                            </div>
                            <div className="col col-3">
                                <button onClick={async () =>{await requestApiJackpot('/deleteformfield', 'post', {id: form_field.id}); load()}} className="btn btn-danger">
                                    Supprimer
                                </button>
                            </div>
                            <div className="col col-6">
                                <div className="row">
                                    <I18n form_field={form_field} load={load}/>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </>

    )
}

function I18n({form_field, load}){

    const [value, setValue] = useState('');
    const [language_code, set_language_code] = useState('fr');

    return(
        <>
            <div className="col col-12 ">
                <input onChange={(e)=>{setValue(e.target.value)}} placeholder="Traduction" className="form-control" value={value}/>
            </div>
            <div className="col col-12">
                {
                    form_field.i18n.map((i18n)=>{
                        return(
                            <div className="row" key={i18n.id}>
                                <div className="col col-6">
                                    <span>
                                        {i18n.language_code}
                                    </span>
                                </div>
                                <div className="col col-6">
                                    <span>
                                        {i18n.value}
                                    </span>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
            <div className="col col-6 mt-1">
                <select onChange={(e)=>{set_language_code(e.target.value)}} className="form-control" value={language_code}>
                    <option value="fr">FR</option>
                    <option value="nl">NL</option>
                    <option value="en">EN</option>
                </select>
            </div>
            <div className="col col-6 mt-1">
                <button onClick={async () => {await requestApiJackpot('/createi18n', 'post', {input:{form_field_id: form_field.id, value: value, language_code: language_code}}); load()}} className="btn btn-warning">Modifier</button>
            </div>
        </>
    )
}